import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import PostsContainer from "../global/atoms/PostsContainer"
import { ListedCaseStudy } from "./ListedCaseStudy"

const Container = styled.article`
  margin-top: 55px;

  transition: transform 250ms, opacity 250ms;

  @media ${maxD.tabletL} {
    margin-top: 25px;
  }

  @media ${maxD.mobileL} {
    margin-top: 35px;
  }
`

const CaseStudiesList = ({ caseStudies, isVisible }) => (
  <Container
    style={
      isVisible || {
        opacity: 1,
        transform: "translateY(5rem)",
      }
    }
  >
    <PostsContainer>
      {caseStudies.map(caseStudy => (
        <ListedCaseStudy data={caseStudy} key={caseStudy.id} />
      ))}
    </PostsContainer>
  </Container>
)

export { CaseStudiesList }
